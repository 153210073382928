<template>
  <main
    class="color-primary h-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t('SUPPLIER_PAYMENT.CANCELATION') }} Gift-Card
    </p>
    <h1 class="content-margin sub-title">{{ $t('CANCELLATION.LOADING_DETAILS') }}</h1>
    <section class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="cancel_list"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
        <template v-slot:[`item.supplier`]="{ item }">
          <div class="ticket-btn">
            {{item.supplier}}
          </div>
        </template>
      </v-data-table>
    </section>
    <section class="botton-action">
      <div class="d-flex align-items-center timing-vat m-b-15">
        <v-checkbox
          color="#0D3856"
          class="cus-checkbox"
          v-model="isCancellation"
        ></v-checkbox>
        <label class="px10 color-primary cancel-label">{{
          $t('CANCELLATION.CANCELLATION_IN_SYSTEM')
        }}</label>
        <div class="d-flex align-items-center" v-if="isCancellation">
          <label class="space-nowrap">{{ $t('CANCELLATION.AMOUNT_TO_BE_CANCELED') }}</label>
          <input
            type="text"
            class="input px10 color-red4 mx10"
            value="38"
            @keypress="isNumber($event)"
          />
        </div>
      </div>
      <button class="primary-btn bg-red2">
        {{ $t('MANAGE_SHANIV.VOID') }}
      </button>
    </section>
  </main>
</template>

<script>
export default {
  name: 'CancellationGiftcart',
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('REGISTER_RESELLER.BUSINESS_NAME'),
          align: 'center',
          sortable: false,
          value: 'business_name',
        },
        {
          text: this.$t('REPORT_SALES.MOBILE'),
          align: 'center',
          sortable: false,
          value: 'mobile',
        },
        {
          text: this.$t('REPORT_SALES.SUPPLIER_ID'),
          align: 'center',
          sortable: false,
          value: 'supplier',
        },
        {
          text: this.$t('GIFTCARD_PAYMENT_RESULT.PRODUCT'),
          align: 'center',
          sortable: false,
          value: 'product',
        },
        {
          text: this.$t('COMMON.DATE'),
          align: 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: this.$t('COMMON.HOUR'),
          align: 'center',
          sortable: false,
          value: 'hour',
        },
        {
          text: this.$t('REPORT_SALES.CONSUMER_PRICE'),
          align: 'center',
          sortable: false,
          value: 'consumer_price',
        },
        {
          text: this.$t('REPORT_SALES.COST_PRICE'),
          align: 'center',
          sortable: false,
          value: 'cost_price',
        },
      ],
      cancel_list: [
        {
          business_name: 'הפיצוציה',
          mobile: '0543095860',
          supplier: 'סלקום',
          product: 'טוקמן 59 ללא הגבלה',
          date: '28-01-2019',
          hour: '23:11:52',
          consumer_price: '59',
          cost_price: '-54.21',
        },
      ],
      isCancellation: false,
    };
  },
  mounted() {
    document.getElementsByTagName("html")[0].style.background = "#FBFFD8";
  },
};
</script>
<style lang="scss" scoped>
.table-content-part {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

.botton-action {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  .primary-btn {
    margin: 0 15px;
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
  .cancel-label {
    margin-left: 50px;
  }
}
.ticket-btn {
  background: #6D216F;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .botton-action {
    margin: 20px;
  }
}
</style>